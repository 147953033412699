/* eslint-disable react/prop-types */
import * as React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';
import { DividerLineDivider } from 'components/Divider/LineDivider/DividerLineDivider';

const Main = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 12px;

  a {
    color: inherit;
    &:hover {
      color: ${colorsV2.primary100};
    }
  }
`;

export const ColumnInfo = ({ title, list }) => {
  return (
    <Main>
      <ResponsiveTypography.Paragraph variant="bold/14-20" color="gray100">
        {title}
      </ResponsiveTypography.Paragraph>
      <DividerLineDivider
        direction="horizontal"
        size={1}
        color={colorsV2.black80}
      />
      {list?.map((item, idx) => (
        <ResponsiveTypography.Paragraph
          variant="medium/14-20"
          color="white100"
          key={idx}
        >
          <a
            href={item?.link}
            target={item?.openNewTab ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {item?.name}
          </a>
        </ResponsiveTypography.Paragraph>
      ))}
    </Main>
  );
};
